.survey-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    gap: 30px;
}

.survey-container h1 {
    margin-bottom: 0px;
}

.likert-scale {
    background-color: rgb(241, 241, 241);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
}

.likert-question {
    font-weight: bold;
    margin-bottom: 20px;
}

.likert-options {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.likert-option {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.likert-option input[type="radio"] {
    margin-bottom: 5px;
}

.likert-button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}